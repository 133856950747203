import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'convite',
        path: `${AUTH_PREFIX_PATH}/convite`,
        component: React.lazy(() => import('views/auth-views/authentication/convite')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'pagamento',
        path: `${APP_PREFIX_PATH}/pagamento`,
        component: React.lazy(() => import('views/app-views/pagamento')),
    },
    {
        key: 'conta',
        path: `${APP_PREFIX_PATH}/conta`,
        component: React.lazy(() => import('views/app-views/conta')),
    },
    {
        key: 'biblioteca',
        path: `${APP_PREFIX_PATH}/biblioteca`,
        component: React.lazy(() => import('views/app-views/biblioteca')),
    },
    {
        key: 'suporte',
        path: `${APP_PREFIX_PATH}/suporte`,
        component: React.lazy(() => import('views/app-views/suporte')),
    },
    {
        key: 'plano',
        path: `${APP_PREFIX_PATH}/plano`,
        component: React.lazy(() => import('views/app-views/plano')),
    },
    {
        key: 'checkout',
        path: `${APP_PREFIX_PATH}/checkout`,
        component: React.lazy(() => import('views/app-views/checkout')),
    },
    {
        key: 'sucesso',
        path: `${APP_PREFIX_PATH}/sucesso`,
        component: React.lazy(() => import('views/app-views/sucesso')),
    },
    {
        key: 'imovel',
        path: `${APP_PREFIX_PATH}/imovel`,
        component: React.lazy(() => import('views/app-views/imovel')),
    }
]